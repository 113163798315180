
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination } from "@/libs/utils";
import { Specialist } from "@/store/specialist/types";

const VSpecialistList = defineComponent({
  name: "VSpecialistList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de especialistas | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { hasFilters } = useFilter();
    const specialists = computed(() => store.state.specialist.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(specialist: Specialist) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "specialist-edit", params: { _id: String(specialist.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "specialist-edit", params: { _id: String(specialist.id) } },
        },
        {
          label: specialist.cd_usuario_django ? "Desvincular" : "Vincular",
          icon: specialist.cd_usuario_django ? "fas fa-unlink" : "fas fa-link",
          to: {
            name: specialist.cd_usuario_django ? "specialist-unlink" : "specialist-link",
            params: { _id: String(specialist.id) },
          },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "specialist-delete", params: { _id: String(specialist.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Especialistas",
        icon: "fas fa-user-md mr-1",
        to: { name: "specialist-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "specialist",
      setPage: "setSpecialistPaginationPage",
      updateList: getSpecialists,
    });

    async function getSpecialists() {
      loading.list = true;
      await store.dispatch("getSpecialists", { config: { useCompany: true } });
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "specialist-filter", query: route.query });
    else getSpecialists();

    return {
      specialists,
      pagination,
      loading,
      hasFilters,
      breadcrumb,
      getDropdownOptions,
      handleUpdateCurrentPage,
      getSpecialists,
    };
  },
});

export default VSpecialistList;
